<template>
  <b-row>
    <b-col cols="12">
      <b-card-code
        title=""
        no-body
      >
        <b-card-body>

          <b-form @submit.prevent="submit">
            <b-row>

              <!-- Type -->
              <b-col md="6">
                <b-form-group
                  label="Type"
                  label-for="mc-type"
                >
                  <v-select
                    id="mc-type"
                    v-model="asset.type"
                    :options="assetTypes"
                    placeholder="Type"
                    class="w-100"
                  />
                </b-form-group>
              </b-col>

              <hr class="w-100">

              <!-- Trailer Type -->
              <b-col
                v-if="asset.type ==='Trailer'"
                md="6"
              >
                <b-form-group
                  label="Trailer Type"
                  label-for="mc-trailer_type"
                >
                  <v-select
                    id="mc-trailer_type"
                    v-model="asset.trailer_type"
                    :options="trailer_types"
                    placeholder="Trailer Type"
                    class="w-100"
                  />
                </b-form-group>
              </b-col>

              <!-- Trailer Size -->
              <b-col
                v-if="asset.type ==='Trailer'"
                md="6"
              >
                <b-form-group
                  label="Trailer Size"
                  label-for="mc-trailer_size"
                >
                  <v-select
                    id="mc-trailer_size"
                    v-model="asset.trailer_size"
                    :options="trailer_sizes"
                    placeholder="Trailer Size"
                    class="w-100"
                  />
                </b-form-group>
              </b-col>

              <hr
                v-if="asset.type ==='Trailer'"
                class="w-100"
              >

              <!-- Make -->
              <b-col md="6">
                <b-form-group
                  label="Make"
                  label-for="mc-make"
                >
                  <b-form-input
                    id="mc-make"
                    v-model="asset.make"
                    placeholder="Make"
                  />
                </b-form-group>
              </b-col>

              <!-- Year -->
              <b-col md="6">
                <b-form-group
                  label="Year"
                  label-for="mc-year"
                >
                  <b-form-input
                    id="mc-year"
                    v-model="asset.year"
                    placeholder="Year"
                  />
                </b-form-group>
              </b-col>

              <!-- Model -->
              <b-col md="6">
                <b-form-group
                  label="Model"
                  label-for="mc-model"
                >
                  <b-form-input
                    id="mc-model"
                    v-model="asset.model"
                    placeholder="Model"
                  />
                </b-form-group>
              </b-col>

              <!-- License Plate Number -->
              <b-col md="6">
                <b-form-group
                  label="License Plate Number"
                  label-for="mc-license_plate_number"
                >
                  <b-form-input
                    id="mc-license_plate_number"
                    v-model="asset.license_plate_number"
                    placeholder="License Plate Number"
                  />
                </b-form-group>
              </b-col>

              <!-- TAG -->
              <b-col md="6">
                <b-form-group
                  label="TAG"
                  label-for="mc-tag"
                >
                  <b-form-input
                    id="mc-tag"
                    v-model="asset.tag"
                    placeholder="TAG"
                  />
                </b-form-group>
              </b-col>

              <!-- VIN -->
              <b-col md="6">
                <b-form-group
                  label="VIN"
                  label-for="mc-vin"
                >
                  <b-form-input
                    id="mc-vin"
                    v-model="asset.vin"
                    placeholder="VIN"
                  />
                </b-form-group>
              </b-col>

              <!-- Date of Purchased -->
              <b-col md="6">
                <b-form-group
                  label="Date of Purchased"
                  label-for="mc-date_purchased"
                >
                  <cleave
                    id="mc-date_purchased"
                    v-model="asset.date_purchased"
                    :options="options.date"
                    class="form-control"
                    :raw="false"
                    placeholder="Date of Purchased"
                  />
                </b-form-group>
              </b-col>

              <!-- Price of Purchased -->
              <b-col md="6">
                <b-form-group
                  label="Price of Purchased"
                  label-for="mc-price_purchased"
                >
                  <b-form-input
                    id="mc-price_purchased"
                    v-model="asset.price_purchased"
                    placeholder="Price of Purchased"
                  />
                </b-form-group>
              </b-col>

              <!-- Mileage -->
              <b-col md="6">
                <b-form-group
                  label="Mileage"
                  label-for="mc-mileage"
                >
                  <b-form-input
                    id="mc-mileage"
                    v-model="asset.mileage"
                    placeholder="Mileage"
                  />
                </b-form-group>
              </b-col>

              <hr class="w-100">

              <!-- ELD Provider -->
              <b-col md="6">
                <b-form-group
                  label="ELD Provider"
                  label-for="mc-eld_provider"
                >
                  <b-form-input
                    id="mc-eld_provider"
                    v-model="asset.eld_provider"
                    placeholder="ELD Provider"
                  />
                </b-form-group>
              </b-col>

              <!-- ELD Device Name -->
              <b-col md="6">
                <b-form-group
                  label="ELD Device Name"
                  label-for="mc-eld_device_name"
                >
                  <b-form-input
                    id="mc-eld_device_name"
                    v-model="asset.eld_device_name"
                    placeholder="ELD Device Name"
                  />
                </b-form-group>
              </b-col>

              <!-- ELD Serial Number -->
              <b-col md="6">
                <b-form-group
                  label="ELD Serial Number"
                  label-for="mc-eld_serial_number"
                >
                  <b-form-input
                    id="mc-eld_serial_number"
                    v-model="asset.eld_serial_number"
                    placeholder="ELD Serial Number"
                  />
                </b-form-group>
              </b-col>

              <!-- ELD Unit -->
              <b-col md="6">
                <b-form-group
                  label="ELD Unit"
                  label-for="mc-eld_unit"
                >
                  <b-form-input
                    id="mc-eld_unit"
                    v-model="asset.eld_unit"
                    placeholder="ELD Unit"
                  />
                </b-form-group>
              </b-col>

              <hr class="w-100">

              <!-- GPS Provider -->
              <b-col md="6">
                <b-form-group
                  label="GPS Provider"
                  label-for="mc-gps_provider"
                >
                  <b-form-input
                    id="mc-gps_provider"
                    v-model="asset.gps_provider"
                    placeholder="GPS Provider"
                  />
                </b-form-group>
              </b-col>

              <!-- GPS Device Name -->
              <b-col md="6">
                <b-form-group
                  label="GPS Device Name"
                  label-for="mc-gps_device_name"
                >
                  <b-form-input
                    id="mc-gps_device_name"
                    v-model="asset.gps_device_name"
                    placeholder="GPS Device Name"
                  />
                </b-form-group>
              </b-col>

              <!-- GPS Serial Number -->
              <b-col md="6">
                <b-form-group
                  label="GPS Serial Number"
                  label-for="mc-gps_serial_number"
                >
                  <b-form-input
                    id="mc-gps_serial_number"
                    v-model="asset.gps_serial_number"
                    placeholder="GPS Serial Number"
                  />
                </b-form-group>
              </b-col>

              <!-- GPS Unit -->
              <b-col md="6">
                <b-form-group
                  label="GPS Unit"
                  label-for="mc-gps_unit"
                >
                  <b-form-input
                    id="mc-gps_unit"
                    v-model="asset.gps_unit"
                    placeholder="GPS Unit"
                  />
                </b-form-group>
              </b-col>

              <hr class="w-100">

              <!-- submit and reset -->
              <b-col md="12">
                <b-button
                  type="submit"
                  variant="primary"
                  class="mr-1"
                >
                  Submit
                </b-button>
              </b-col>
            </b-row>
          </b-form>
          
        </b-card-body>
      </b-card-code>
    </b-col>
  </b-row>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BRow, BCol, BForm, BButton, BCardBody, BFormGroup, BFormInput,
} from 'bootstrap-vue'
import Cleave from 'vue-cleave-component'
import vSelect from 'vue-select'
import * as assetService from '@/services/asset'

export default {
  components: {
    BCardCode,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BCardBody,
    vSelect,
    Cleave,
  },
  data() {
    return {
      asset: {
        type: '',
        year: '',
        make: '',
        model: '',
        license_plate_number: '',
        tag: '',
        vin: '',
        date_purchased: '',
        price_purchased: '',
        mileage: '',
        eld_provider: '',
        eld_device_name: '',
        eld_serial_number: '',
        eld_unit: '',
        gps_provider: '',
        gps_device_name: '',
        gps_serial_number: '',
        gps_unit: '',
        trailer_type: '',
        trailer_size: '',
      },
      options: {
        date: {
          date: true,
          delimiter: '-',
          datePattern: ['Y', 'm', 'd'],
        },
        phone: {
          phone: true,
          phoneRegionCode: 'US',
        },
      },
      assetTypes: ['Tractor', 'Trailer', 'Container'],
      trailer_types: ['Flat Bed', 'Dry Van', 'Reefer', 'Lowboy', 'Step Deck', 'Extendable Flatbed/Stretch', 'Stretch Single', 'RGN', 'Stretch RGN', 'Conestoga'],
      trailer_sizes: ['28ft', '32 ft', '34 ft', '36 ft', '40 ft', '45 ft', '48 ft', '53 ft'],
      editId: '',
    }
  },
  created() {
    if (this.$route.name === 'edit-asset') {
      this.editId = this.$route.params.id
      this.getAssetById(this.$route.params.id)
    }
  },
  methods: {
    // store company
    submit() {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, submit it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$store.commit('set_loading_action', true)
          if (this.$route.name === 'add-asset') {
            assetService.create({
              ...this.asset,
              company_id: this.$store.state.auth.companyId,
            })
              .then(res => {
                this.$store.commit('set_loading_action', false)
                this.$swal({
                  title: 'Success!',
                  text: res.data.message,
                  icon: 'success',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                }).then(() => {
                  this.$router.push({ name: 'asset' })
                })
              })
              .catch(err => {
                this.$store.commit('set_loading_action', false)
                this.$swal({
                  title: 'Failed!',
                  text: err.response.data.message,
                  icon: 'error',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                })
              })
          } else {
            assetService.update({
              ...this.asset,
              id: this.editId,
              company_id: this.$store.state.auth.companyId,
            })
              .then(res => {
                this.$store.commit('set_loading_action', false)
                this.$swal({
                  title: 'Success!',
                  text: res.data.message,
                  icon: 'success',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                }).then(() => {
                  this.$router.push({ name: 'asset' })
                })
              })
              .catch(err => {
                this.$store.commit('set_loading_action', false)
                this.$swal({
                  title: 'Failed!',
                  text: err.response.data.message,
                  icon: 'error',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                })
              })
          }
        }
      })
    },

    // get company by id in edit
    getAssetById(id) {
      this.$store.commit('set_loading_action', true)
      assetService.edit(id)
        .then(res => {
          this.$store.commit('set_loading_action', false)
          this.asset = res.asset
        })
        .catch(err => {
          this.$store.commit('set_loading_action', false)
          console.log(err)
        })
    },

    // select logo
    selectLogo(event) {
      const input = event.target
      if (input.files && input.files[0]) {
        const reader = new FileReader()
        reader.onload = e => {
          this.previewLogo = e.target.result
          this.company.logo = this.previewLogo
        }
        reader.readAsDataURL(input.files[0])
      }
    },
  },
}
</script>
<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  .vs__dropdown-toggle {
    border: 1px solid #ebe9f1;
    max-height: 70px;
    overflow: auto;
  }
</style>
